<template>
  <main>
    <header-black />
    <boats-descr />
    <boats-list />
    <home-contact-us />
  </main>
</template>

<script>
export default {
  name: 'BoatsPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    BoatsDescr: () => import('@/components/BoatsDescr'),
    BoatsList: () => import('@/components/BoatsList'),
    HomeContactUs: () => import('@/components/HomeContactUs')
  }
}
</script>
